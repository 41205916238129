<template>
  <v-container v-if="$temPermissao('leituras-listagem')" fluid class="pa-0">
    <dashboard-filtros
      v-if="showFilters"
      @close-filters="showFilters = false"
      v-model="filtros"
      :carregando="carregando"
      :minTimeToRefesh="minTimeToRefesh"
      @buscar="buscarLeituras(true)"
      @cancelar-selecionar-leituras="cancelarSelecionarLeituras()"
      @relatorio-proximidade="relatorioProximidade()"
      @selecionar-varias-leituras="selecionarVariasLeituras()"
      @change-time-to-refresh-status="changeTimeToRefreshStatus"
    />
    <div v-else class="container__btn__filters">
      <v-progress-linear
        v-if="carregando"
        color="primary"
        absolute
        indeterminate
        class="container__btn__filters__loader"
      />
    </div>

    <v-row v-if="leituras.length <= 0 && !carregando" class="px-5">
      <v-col>
        <i>Nenhum registro encontrado</i>
      </v-col>
    </v-row>

    <v-item-group multiple v-model="leiturasSelecionadas">
      <v-row class="px-5">
        <v-col
          v-for="(leitura, key) in leituras"
          :key="key"
          cols="12"
          sm="6"
          md="3"
          xl="2"
          class="pa-2"
        >
          <v-item v-slot="{ active, toggle }" :value="leitura">
            <card-leitura
              :cor="active ? 'info' : ''"
              :leitura="leitura"
              :card-selecionavel="filtros.selecionarLeituras"
              :carregando="carregandoDetalhesLeitura === leitura.leitura_id"
              :imagens-erro="imagensErro"
              @click-imagem="clickImagemCard(leitura, toggle)"
              @erro-carregar-imagem="erroCarregarImagem(leitura.leitura_id)"
            />
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>

    <v-row>
      <v-col class="text-center">
        <v-btn
          class="mr-2"
          color="secondary"
          :disabled="pagina <= 1 || carregando"
          :loading="carregandoRetornarPagina"
          @click="trocarPagina(true)"
        >
          <v-icon v-text="'mdi-arrow-left-bold'" />
        </v-btn>
        <v-btn
          color="secondary"
          :disabled="carregando"
          :loading="carregandoAvancarPagina"
          @click="trocarPagina()"
        >
          <v-icon v-text="'mdi-arrow-right-bold'" />
        </v-btn>
      </v-col>
    </v-row>

    <dialog-detalhes-leitura
      v-if="dialogDetalhesLeitura"
      v-model="dialogDetalhesLeitura"
      :leitura="detalhesLeitura"
    />
  </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import apiLeituras from "@/api/leituras";
import formatarData from "@/utils/formatar-data-input";

export default {
  components: {
    DialogDetalhesLeitura: () => import("@/components/dialog/leitura/DialogDetalhesLeitura"),
    DashboardFiltros: () => import("@/views/pages/dashboard/DashboardFiltros"),
    CardLeitura: () => import("@/components/CardLeitura"),
  },

  data() {
    return {
      leituras: [],
      detalhesLeitura: null,
      dialogDetalhesLeitura: false,
      carregando: false,
      carregandoAvancarPagina: false,
      carregandoRetornarPagina: false,
      carregandoDetalhesLeitura: false,
      timer: null,
      imagensErro: [],
      leiturasSelecionadas: [],
      pagina: 1,
      filtros: {
        atualizarAutomaticamente: true,
        quantidadePaginacao: 28,
        segundosAtualizar: 5,
        selecionarLeituras: false,
        campos: {
          data_inicio: "",
          data_fim: "",
          hora_inicio: "",
          hora_fim: "",
        },
      },
      minTimeToRefesh: 5,
    };
  },

  computed: {
    ...mapState('filters', ['showFilters'])
  },

  watch: {
    'filtros.atualizarAutomaticamente'(val) {
      this.updateAutoSearchTime(val)
    },
    'filtros.segundosAtualizar'(val) {
      this.updateAutoSearchTime(true)
    },
  },

  created() {
    this.buscarTodasLeiturasPlaca();
  },

  mounted() {
    this.buscarLeituras();

    if (this.filtros.atualizarAutomaticamente) {
      this.timer = setInterval(
        this.buscarLeituras,
        this.filtros.segundosAtualizar * 1000
      );

      return
    }
  },

  beforeDestroy() {
    this.updateAutoSearchTime(false)
  },

  methods: {
    ...mapMutations({
      setProximidadeLeiturasSelecionadas:
        "relatorioProximidade/SET_LEITURAS_SELECIONADAS",
    }),

    changeTimeToRefreshStatus(status) {
      this.timeToRefreshIsValid = status
    },

    updateAutoSearchTime(update) {
      if (this.filtros.segundosAtualizar < this.minTimeToRefesh) return

      if (update) {
        if (this.timer) {
          clearInterval(this.timer)
          this.timer = null
        }

        this.timer = setInterval(
          this.buscarLeituras,
          this.filtros.segundosAtualizar * 1000
        )
      }

      if (!update) {
        if (this.timer) {
          clearInterval(this.timer)
          this.timer = null
        }

        return
      }
    },

    async buscarLeituras(resetarPaginacao = false) {
      if (this.filtros.segundosAtualizar < this.minTimeToRefesh) this.filtros.segundosAtualizar = this.minTimeToRefesh
      if (this.carregando) return

      if (this.$route.name !== 'Dashboard') {
        this.updateAutoSearchTime(false)

        return
      }

      try {
        this.carregando = true;

        if (resetarPaginacao) {
          this.pagina = 1;
        }

        const filtros = { ...this.filtros.campos };

        filtros.por_pagina = this.filtros.quantidadePaginacao;
        filtros.pagina = this.pagina;

        if(filtros.hora_inicio!=null){
          filtros.hora_inicio = formatarData.horario(filtros.hora_inicio)
        }

        if(filtros.hora_fim!=null){
          filtros.hora_fim = formatarData.horario(filtros.hora_fim)
        }


        const dados = await apiLeituras.dashboard(filtros);
        this.leituras = dados.data.data;
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.carregando = false;
        this.carregandoAvancarPagina = false;
        this.carregandoRetornarPagina = false;
      }
    },

    async buscarDetalhesLeitura(leitura) {
      try {
        if (!this.$temPermissao("leituras-visualizacao")) {
          return;
        }

        this.carregandoDetalhesLeitura = leitura.leitura_id;
        const dados = await apiLeituras.detalhes(leitura.leitura_id);

        this.detalhesLeitura = dados.data;

        if (this.imagensErro.includes(leitura.leitura_id)) {
          this.detalhesLeitura.imagem_lazy_src =
            this.$store.state.layout.imagemLeituraCarregando;
        } else {
          this.detalhesLeitura.imagem_lazy_src =
            "data:image/png;base64," + leitura.imagem;
        }

        this.dialogDetalhesLeitura = true;
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.carregandoDetalhesLeitura = false;
      }
    },

    erroCarregarImagem(leituraId) {
      this.imagensErro.push(leituraId);
    },

    trocarPagina(voltar = false) {
      this.filtros.atualizarAutomaticamente = false;

      if (voltar) {
        this.carregandoRetornarPagina = true;
        this.pagina--;
      } else {
        this.carregandoAvancarPagina = true;
        this.pagina++;
      }

      this.buscarLeituras();
    },

    clickImagemCard(leitura, toggle) {
      if (this.filtros.selecionarLeituras) {
        return toggle();
      }

      this.buscarDetalhesLeitura(leitura);
    },

    selecionarVariasLeituras() {
      this.leituras.map((item) => {
        this.leiturasSelecionadas.push(item);
      });
    },

    cancelarSelecionarLeituras() {
      this.leiturasSelecionadas = [];
      this.filtros.selecionarLeituras = false;
    },

    relatorioProximidade() {
      if (this.leiturasSelecionadas.length <= 0) {
        return this.$snackbar.mostrar({
          mensagem: "Selecione pelo menos uma passagem",
        });
      }
      this.setProximidadeLeiturasSelecionadas(this.leiturasSelecionadas);
      this.cancelarSelecionarLeituras();
      this.$router.push("/relatorios/proximidade/filtros");
    },
    buscarTodasLeiturasPlaca() {
      try {
        const url = window.location;
        const placa = new URLSearchParams(url.search).get("placa");
        if (!placa) {
          return;
        } else {
          this.filtros.campos.placa = placa;
          this.buscarLeituras();
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container__btn__filters {
  position: relative;
  padding: 16px;

  &__loader {
    position: absolute;
    top: 0;
  }
}
</style>
